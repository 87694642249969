@import "src/theme/colors";
@import "src/theme/fonts";

#root {
  height: 100%;
  body:not(.mobile) & {
    display: flex;
    align-items: center;
  }
}

body {
  margin: 0;
  height: 100%;
  font-family: $font-family-common;
  background: #a6dff2;
  user-select: none;
  color: #412304;

  &.mobile {
    background: $background;
    #root {
      background: #a6dff2;
    }
  }
}

html {
  height: 100%;
}

img {
  object-fit: contain;
}

a {
  color: #613a11;
}

video {
  object-fit: cover;
}

a {
  color: #613a11;
}

body:not(.mobile) {
  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background: #e3c19a;
    box-shadow: inset -2px -2px 5px rgba(97, 58, 17, 0.8),
      inset 2px 2px 5px rgba(97, 58, 17, 0.8);
    border-radius: 50px;
  }

  ::-webkit-scrollbar-thumb {
    background: #f7c104;
    box-shadow: inset -3px -3px 5px rgba(0, 0, 0, 0.25),
      inset 3px 3px 5px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
