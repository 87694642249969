.point {
  z-index: 2;
}

.point-label {
  width: fit-content;
  max-width: 200px;
  height: fit-content;
  border-radius: 10px;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  background: white;
}

.point-1 {
  position: absolute;
  left: 3%;
  top: 26.3%;

  &.point-label {
    transform: translate(-13%, -105%);
  }
}
.point-2 {
  position: absolute;
  left: 17.2%;
  top: 51%;

  &.point-label {
    transform: translate(-13%, -105%);
  }
}
.point-3 {
  position: absolute;
  left: 16.7%;
  top: 75%;

  &.point-label {
    transform: translate(-13%, -105%);
  }
}
.point-4 {
  position: absolute;
  left: 35.7%;
  top: 78.5%;

  &.point-label {
    transform: translate(-13%, -105%);
  }
}
.point-5 {
  position: absolute;
  left: 61.2%;
  top: 47.6%;

  &.point-label {
    transform: translate(-13%, -105%);
  }
}
.point-6 {
  position: absolute;
  left: 64.17%;
  top: 87.76%;

  &.point-label {
    transform: translate(-13%, -105%);
  }
}
.point-7 {
  position: absolute;
  left: 90.7%;
  top: 74.46%;

  &.point-label {
    transform: translate(-13%, -105%);
  }
}
.point-8 {
  position: absolute;
  left: 88.5%;
  bottom: 46.57%;

  &.point-label {
    transform: translate(-13%, -125%);
  }
}
.point-9 {
  position: absolute;
  left: 69.27%;
  bottom: 71%;

  &.point-label {
    transform: translate(-13%, -125%);
  }
}
.point-10 {
  position: absolute;
  left: 38.38%;
  bottom: 60%;

  &.point-label {
    transform: translate(-13%, -125%);
  }
}

.mobile {
  .point-label {
    font-size: 11px;
    opacity: 0.8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .point-1 {
    left: 17%;
    top: 12%;

    &.point-label {
      transform: translate(-30%, 250%);
    }
  }
  .point-2 {
    left: 39.5%;
    top: 25.5%;

    &.point-label {
      transform: translate(-13%, 250%);
    }
  }
  .point-3 {
    left: 70%;
    top: 22.5%;

    &.point-label {
      transform: translate(-45%, 250%);
    }
  }
  .point-4 {
    left: 79%;
    top: 41%;

    &.point-label {
      transform: translate(-50%, 250%);
    }
  }
  .point-5 {
    left: 46%;
    top: 38.5%;

    &.point-label {
      transform: translate(-80%, 240%);
    }
  }
  .point-6 {
    left: 4%;
    top: 51.5%;

    &.point-label {
      transform: translate(-13%, 250%);
    }
  }
  .point-7 {
    left: 42%;
    top: 52.5%;

    &.point-label {
      transform: translate(-13%, 250%);
    }
  }
  .point-8 {
    left: 76%;
    top: 69%;

    &.point-label {
      transform: translate(-60%, 250%);
    }
  }
  .point-9 {
    left: 40%;
    top: 73.5%;

    &.point-label {
      transform: translate(-13%, 250%);
    }
  }
  .point-10 {
    left: 22%;
    top: 89%;

    &.point-label {
      transform: translate(-13%, 250%);
    }
  }
}
