.daily-map-container {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  &__filled {
    background: url("../../../../images/map/map-front.svg") no-repeat center;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: relative;
  }
}

.badge.disabled {
  opacity: .7;
  pointer-events: none;
}

.mobile .daily-map-container {
  display: flex;
  align-items: center;

  &__filled {
    background: url("../../../../images/map/map-mobile-front.png") no-repeat center;
    background-size: cover;
    width: 360px;
    height: 640px;
    margin: auto;
  }
}
