$yellow: #ffcd35;
$lighter-yellow: #fff400;
$light-yellow: #fae87e;
$middle-yellow: #ffdc60;
$dark-yellow: #f7c104;

$beige: #e5e0cf;
$lighter-beige: #fbf4ed;
$gray-15: rgba(52, 52, 52, 0.15);
$black-25: rgba(0, 0, 0, 0.25);
$light-beige: #eee9d7;
$light-middle-beige: #ffe7d0;
$middle-beige: #ecd8c0;
$dark-beige: #d9d2b8;

$grey: #9ca3a4;
$transparent-dark-gray: rgba(163, 101, 85, 0.2);

$brown: #9a7243;
$grey-brown: #937d65;
$middle-brown: #edd9c1;
$light-brown: #e3c19a;
$light-middle-brown: #e2c099;
$light-dark-brown: #cda472;
$dark-brown: #d9b280;
$strong-dark-brown: #613a11;

$green: #00a753;
$light-green: #04a253;
$dark-green: #047c44;

$light-orange: #f7c104;

$red: #ff0303;
$light-red: #fb7b76;

$white: #fbf4ed;

$black: #000000;

$light-blue: #f3fbfd;

$dashboard-shadow: 2px 2px 8px rgba(97, 58, 17, 0.8);
$shadow-primary: 5px 5px 5px rgba(0, 0, 0, 0.5),
  inset -5px -5px 20px rgba(0, 0, 0, 0.25);
$shadow-secondary: 5px 5px 5px rgba(0, 0, 0, 0.5),
  inset -5px -5px 20px rgba(0, 0, 0, 0.25),
  inset 5px 5px 20px rgba(0, 0, 0, 0.25);
$shadow-tertiary: inset 2px 2px 5px rgba(97, 58, 17, 0.25),
  inset -2px -2px 5px rgba(97, 58, 17, 0.25);
$text-shadow-white: 5px 5px 10px #ffffff;
$light-shadow: 2px 2px 5px rgba(97, 58, 17, 0.8),
  inset -5px -5px 10px rgba(97, 58, 17, 0.25),
  inset 5px 5px 10px rgba(97, 58, 17, 0.25);
$shadow-checkbox: inset 2px 2px 3px rgba(97, 58, 17, 0.6);
$right-bottom-shadow: 3px 3px 5px #613a11;
$outer-light-shadow: 2px 2px 5px rgba(97, 58, 17, 0.8);
$yellow-blue-shadow-l: -5px -5px 0px #ff0000, 5px 5px 0px #243afc;
$yellow-blue-shadow-s: -3px -3px 0px #ff0303, 3px 3px 0px #243afc;
$clients-table-shadow: 1px 1px 3px rgba(97, 58, 17, 0.8),
  inset -2px -2px 5px rgba(97, 58, 17, 0.25),
  inset 2px 2px 5px rgba(97, 58, 17, 0.25);

$background: $beige;
$page-background: $middle-brown;
$content-container-background: $middle-brown;
$error-message-color: $light-red;
$icon-color: $white;
$checkbox-background: $lighter-beige;

$text-input-background: $white;
$text-input-shadow: $shadow-checkbox;
$input-border-background: $middle-beige;
$input-border-shadow: $right-bottom-shadow;

$label-background: $light-brown;
$register-row-input: $gray-15;
$label-shadow: $black-25;

$primary-button-background: $light-orange;
$secondary-button-background: $light-yellow;
$tertiary-button-background: $light-brown;
$button-shadow: $light-shadow;

$icon-button-shadow: $light-shadow;

$title-shadow: $yellow-blue-shadow-l;
$subtitle-shadow: $yellow-blue-shadow-s;

$full-name-cell-background: $light-brown;

$text: #412304;

// export as variables that can be used in javascript
// ignore (webstorm) syntax error https://stackoverflow.com/questions/58729941/unknown-pseudo-selector-export-in-webstorm
// noinspection CssInvalidPseudoSelector
:export {
  iconColor: $icon-color;
  text: $text;
  lightBrown: $light-brown;
}
