@mixin positions($location, $topBase, $leftBase) {
  &--#{$location}-1 {
    position: absolute;
    top: $topBase + 4%;
    left: $leftBase + 4%;
  }
  &--#{$location}-2 {
    position: absolute;
    top: $topBase + 11%;
    left: $leftBase + 5%;
  }
  &--#{$location}-3 {
    position: absolute;
    top: $topBase + 20%;
    left: $leftBase + 10%;
  }
  &--#{$location}-4 {
    position: absolute;
    top: $topBase + 20%;
    left: $leftBase + 10%;
  }
}
@mixin coordinates($class) {
  .#{$class} {
    @include positions("NW", 0%, 0%);
    @include positions("N", 0%, 29%);
    @include positions("NE", 0%, 60%);
    @include positions("W", 33%, 0%);
    @include positions("E", 33%, 60%);
    @include positions("SW", 66%, 0%);
    @include positions("S", 66%, 30%);
    @include positions("SE", 66%, 60%);
  }
}
