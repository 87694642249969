$spacer-s: 5px !default;
$spacer-m: 30px !default;
$spacer-l: 100px !default;

$spacers-s: (
  0: 0,
  1: $spacer-s,
  2: $spacer-s * 2,
  3: $spacer-s * 3,
  4: $spacer-s * 4,
  5: $spacer-s * 5,
  6: $spacer-s * 9,
  7: $spacer-s * 10,
  8: $spacer-s * 12,
) !default;

$spacers-m: (
  1: $spacer-m,
  2: $spacer-m * 2,
  3: $spacer-m * 3,
  4: $spacer-m * 4,
  5: $spacer-m * 5,
  6: $spacer-m * 6,
) !default;

$spacers-l: (
  1: $spacer-l,
  2: $spacer-l * 2,
  3: $spacer-l * 3,
  4: $spacer-l * 4,
  5: $spacer-l * 5,
  6: $spacer-l * 6,
  7: $spacer-l * 7,
  8: $spacer-l * 8,
) !default;

$container-width-m: 550px;
$container-width-l: 850px;

$page-width: 1920px;
$page-height: 1080px;

:export {
  pageWidth: $page-width;
  pageHeight: $page-height;
}
