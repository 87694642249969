@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;800;900&display=swap");

@font-face {
  font-family: "Burnstown";
  src: url("fonts/burnstown.ttf") format("truetype");
}

$font-family-common: "Nunito";

$font-size-3XS: 14px;
$font-size-2XS: 17px;
$font-size-XS: 20px;
$font-size-S: 25px;
$font-size-M: 30px;
$font-size-L: 40px;
$font-size-XL: 60px;
$font-size-2XL: 90px;
$font-size-3XL: 110px;

$line-height-5XS: 25px;
$line-height-4XS: 40px;
$line-height-3XS: 49px;
$line-height-2XS: 62px;
$line-height-XS: 74px;
$line-height-S: 87px;
$line-height-M: 99px;
$line-height-L: 126px;
$line-height-XL: 131px;
$line-height-2XL: 161px;
$line-height-3XL: 186px;

$title-line-height: $line-height-3XS;
$menu-letter-line-height: $line-height-XL;
$letters-line-height: $line-height-2XL;
$game-letter-line-height: $line-height-3XL;
